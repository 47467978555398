import { Component, Input, OnInit } from '@angular/core';
import { ButtonComponent } from '../../../../components/form/button/button.component';
import { InputComponent } from '../../../../components/form/input/input.component';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslatedValuePipe } from 'src/app/pipes/translated-value.pipe';
import { FormFieldComponent } from '../../../../components/form/form-field/form-field.component';
import { PriceValuePipe } from 'src/app/pipes/price-value.pipe';
import { CurrencyPipe } from '@angular/common';
import { IconBoxComponent } from '../../../../components/_elements/icon-box/icon-box.component';
import { TranslateEnumPipe } from 'src/app/pipes/translate-enum.pipe';
import { StopPropagationDirective } from 'src/app/directives/stop-propagation.directive';

@Component({
  selector: 'order-product-card',
  templateUrl: './order-product-card.component.html',
  styleUrls: ['./order-product-card.component.scss'],
  standalone: true,
  imports: [
    ButtonComponent,
    InputComponent,
    ReactiveFormsModule,
    TranslatedValuePipe,
    FormFieldComponent,
    PriceValuePipe,
    IconBoxComponent,
    TranslateEnumPipe,
    StopPropagationDirective
  ],
  providers: [PriceValuePipe, CurrencyPipe],
})
export class OrderProductCardComponent implements OnInit {
  @Input() public data: any;

  public formGroup: FormGroup;

  @Input() public isFinalized = false;

  public step = 10;

  constructor() {}

  ngOnInit() {
    this.formGroup = new FormGroup({
      count: new FormControl(),
    });
  }

  public get countValue() {
    return this.formGroup.value.count;
  }

  public get hasValue() {
    const count = this.countValue;
    return count && count > 0;
  }

  public increment() {
    let count = this.countValue;
    count += this.step;
    this.formGroup.get('count').setValue(count);
  }

  public decrement() {
    let count = this.countValue;
    count -= this.step;

    if (count <= 0) {
      count = null;
    }
    this.formGroup.get('count').setValue(count);
  }
}
