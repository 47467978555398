<ng-container [formGroup]="formGroup">
  <div
    class="flex w-full flex-1 justify-between gap-6x border-b border-grey-10 py-2"
  >
    <img
      [src]="data.imageThumbUrl"
      class="flex h-25x w-35x shrink-0 object-contain"
    />

    <div class="flex flex-1 items-center">
      <span
        class="line-clamp-2 text-start font-display text-sm font-medium text-grey-100"
      >
        {{ data.productFullNameTranslated | translatedValue }}
      </span>
    </div>

    <div class="flex items-center" [class.hidden]="isFinalized">
      <button
        appButton
        color="tertiary"
        icon="monochromeSaveCart"
        stopPropagation
      ></button>
    </div>
  </div>
  <div class="flex w-full flex-wrap justify-between gap-6x">
    <form-field label="common.label.amount">
      <span>
        {{ data.amount }}

        {{ data.contentUnitId | translateEnum: "UnitShort" }}
      </span>
    </form-field>
    <form-field label="common.label.price">
      <div class="flex flex-col gap-1x">
        <h5>
          {{ data.baseUnitBuyInPrice | priceValue }}
        </h5>
        <span class="text-grey-70"> 0,22 €/tk </span>
      </div>
    </form-field>
    <form-field label="common.label.sum">
      <h5>
        {{ data.amount * data.baseUnitBuyInPrice | priceValue }}
      </h5>
    </form-field>
  </div>
  <div class="flex w-full">
    <form-field label="common.label.comment">
      <div class="flex items-start gap-5x">
        <icon-box icon="monochromeNote"></icon-box>
        <span>
          {{
            data.orderDetail?.comment ||
              "Auctor non ut curabitur commodo. Urna augue vel nunc fermentum scelerisque ullamcorper tortor vitae sagittis. Ipsum proin leo lorem nullam."
          }}
        </span>
      </div>
    </form-field>
  </div>
</ng-container>
